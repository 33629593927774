import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { BrowserRouter } from 'react-router-dom';

import Base from './Base';

const theme: Theme = createMuiTheme({
  'palette': {
    'background': {
      'default': '#FAFAFA',
    },
  },
});

const App: React.FC = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Base />
      </BrowserRouter>
    </ThemeProvider>
  </React.Fragment>
);

export default App;
