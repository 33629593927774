import React, { lazy, Suspense, useState, useEffect } from 'react';

import { Route, Switch } from 'react-router-dom';

import axios from 'axios';
/* eslint-enable no-process-env */

import { setGlobal } from 'reactn';

import {
  DEFAULT_COUNT_DATE,
  DEFAULT_STAFF_COUNT,
  DEFAULT_WORK_ORDER,
  DEFAULT_PRODUCT_CODE,
  DEFAULT_LINE_STATUS_DATE,
  DEFAULT_LINE_STATUS_ID,
} from './containers/line/defaults';

/* eslint-disable no-process-env */
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['x-api-key'] = process.env.REACT_APP_API_KEY;

const LinePage = lazy(() => import('./containers/line/index'));

const Base: React.FC = () => {

  const [ isGlobalLoading, setIsGlobalLoading ] = useState(true);

  useEffect(() => {

    setGlobal({
      'statusReasons': [],
      'line': {},
      'countDate': DEFAULT_COUNT_DATE,
      'staffCount': DEFAULT_STAFF_COUNT,
      'workOrder': DEFAULT_WORK_ORDER,
      'productCode': DEFAULT_PRODUCT_CODE,
      'lineStatusId': DEFAULT_LINE_STATUS_ID,
      'lineStatusDate': DEFAULT_LINE_STATUS_DATE,
      'isLineSettingUp': false,
      'isUpdatingCount': false,
      'isPausingLine': false,
    }).then(() => setIsGlobalLoading(false)).catch(() => {});

  }, []);

  if (isGlobalLoading) {

    return <div />;

  }

  return (
    <Suspense fallback={<div />}>
      <Switch>

        <Route path={'/:lineSlug'} component={LinePage} />

      </Switch>
    </Suspense>
  );

};

export default Base;
